import React, { Component } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
//import { Link } from 'react-router-dom';
import styled from "styled-components";
import slide1 from '../../assets/img/Slider-01.jpg'
import slide2 from '../../assets/img/Slider-02.jpg'
import slide3 from '../../assets/img/Slider-03.jpg'
import imgGooglePlay from '../../assets/img/google-play-white.png'
import imgAppStore from '../../assets/img/app-store-white.png'
import './Slider.css'

const Container = styled.div`
    display: 'flex';
    width: 100%;
    /* background: #00a07e; */
    justify-content:center;
    align-items:center;
    background-size: contain;
    padding: 20px 0px;
`;

const Slider = () => {
    return ( 
        <Container>
            <Carousel autoPlay={true} interval={10000} infiniteLoop={true} showThumbs={false}>
                <div>
                    <img src={slide1} alt="E-hunter" />
                    <p className="legend" style={{opacity:'1',background:'none'}}>
                        <div className="row mt-5 text-center">
                            <div className="col-lg-6">
                                <a style={{display:'block',height:'100%'}} href="https://play.google.com/store/apps/details?id=bithe.llc.ehunter.ehunter&hl=es_CO&gl=US" target={"_blank"}><img className="imgapp-slider" src={imgGooglePlay} style={{width:'50%'}} alt="E-hunter" /></a>
                            </div>
                            <div className="col-lg-6">
                                <a style={{display:'block',height:'100%'}} href="javascrtip:void(0)" title='Próximamente'>
                                    <img className="imgapp-slider" src={imgAppStore} style={{width:'50%'}} alt="E-hunter" />
                                </a>
                            </div>
                        </div>
                    </p>
                </div>
                <div>
                    <img src={slide2} alt="E-hunter" />
                    <p className="legend" style={{opacity:'1',background:'none'}}>
                        <div className="row mt-5 text-center">
                            <div className="col-lg-6">
                                <a style={{display:'block',height:'100%'}} href="https://play.google.com/store/apps/details?id=bithe.llc.ehunter.ehunter&hl=es_CO&gl=US" target={"_blank"}><img className="imgapp-slider" src={imgGooglePlay} style={{width:'50%'}} alt="E-hunter" /></a>
                            </div>
                            <div className="col-lg-6">
                                <a style={{display:'block',height:'100%'}} href="javascrtip:void(0)" title='Próximamente'>
                                    <img className="imgapp-slider" src={imgAppStore} style={{width:'50%'}} alt="E-hunter" />
                                </a>
                            </div>
                        </div>
                    </p>
                </div>
                <div>
                    <img src={slide3} alt="E-hunter" />
                    <p className="legend" style={{opacity:'1',background:'none'}}>
                        <div className="row mt-5 text-center">
                            <div className="col-lg-6">
                                <a style={{display:'block',height:'100%'}} href="https://play.google.com/store/apps/details?id=bithe.llc.ehunter.ehunter&hl=es_CO&gl=US" target={"_blank"}><img className="imgapp-slider" src={imgGooglePlay} style={{width:'50%'}} alt="E-hunter" /></a>
                            </div>
                            <div className="col-lg-6">
                                <a style={{display:'block',height:'100%'}} href="javascrtip:void(0)" title='Próximamente'>
                                    <img className="imgapp-slider" src={imgAppStore} style={{width:'50%'}} alt="E-hunter" />
                                </a>
                            </div>
                        </div>
                    </p>
                </div>
            </Carousel>
        </Container>
    );
}

export default Slider;