import React from 'react'
import styled from "styled-components";
import imgParty from '../../assets/img/Img_Party_E-Hunter.jpg'
import imgIcon from '../../assets/img/Icono_App_E-Hunter.png'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import './Express.css'

const Container = styled.div`
    display: 'flex';
    width: 100%;
    /* background: #00a07e; */
    justify-content:center;
    align-items:center;
    background-size: contain;
    padding: 20px 0px;
`;

const Express = () => {
    return ( 
        <Container>
            <img src={imgIcon} style={{width:'5%',marginLeft:'40px',zIndex:'99999'}} alt="E-hunter" />
                <ScrollAnimation duration={3} animateIn='hinge' initiallyVisible={true}>
                    <img src={imgIcon} style={{width:'5%',marginLeft:'40px',zIndex:'99999'}} alt="E-hunter" />
                </ScrollAnimation>
            <div className="row mt-5 mb-5">
                <div className="col-lg-6 text-center">
                    <ScrollAnimation animateIn='flipInY' animateOut='flipOutY'>
                        <span className="mb-5" style={{lineHeight:'55px',fontSize:'45pt',color:'#646464', marginTop:'25%', fontWeight:'bold'}}>Express<br/><span style={{fontSize:'75px'}}>Hunting</span></span>
                        <div className="row mt-4">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8" id="textoExpress">
                            <p style={{fontSize:'13pt',lineHeight:'35px'}}>Te imaginas estar en una discoteca, una playa o un centro comercial y poder hacer encuentros rápidos y casuales con personas que buscan lo mismo que tú?, con nuestro servicio de EXPRESS HUNTING podrás hacer todo esto y mucho mas </p>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </ScrollAnimation>
                </div>
                <div className="col-lg-6">
                    <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
                        <img src={imgParty} style={{width:'75%', borderRadius: '0px 40px 40px 0'}} alt="E-hunter" />
                    </ScrollAnimation>
                </div>
            </div>
        </Container>
    );
}

export default Express;