import React from 'react'
import styled from "styled-components";
import imgIcon from '../../assets/img/Icono_App_E-Hunter.png'
import imgGooglePlay from '../../assets/img/google-play-black.png'
import imgAppStore from '../../assets/img/app-store-black.png'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import './Aplicaciones.css'

const Container = styled.div`
    display: 'flex';
    width: 100%;
    /* background: #00a07e; */
    justify-content:center;
    align-items:center;
    background-size: contain;
    padding: 20px 0px;
`;

const Aplicaciones = () => {
    return ( 
        <Container className="mt-4">
            <ScrollAnimation animateIn='flipInY' animateOut='flipOutY'>
                <center>
                    <img src={imgIcon} style={{width:'5%'}} alt="E-hunter" />
                    <h1 style={{fontWeight: 'bold', color:'#4F4F4F', fontSize: "22pt" }}>
                        Todo comienza con una mirada
                    </h1>
                    <span id="disponibleApps" style={{color: '#1E0E62',fontSize: '15px', fontWeight: 'bold'}}>
                        Disponible gratuitamente en el App Store y Google Play
                    </span> 
                </center>
            </ScrollAnimation>
            <div className="row mt-5 text-center">
                <div className="col-lg-3"></div>
                <div className="col-lg-6">
                   <div className="row">
                    <div className="col-lg-6">
                        <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
                            <a style={{display:'block',height:'100%'}} href="https://play.google.com/store/apps/details?id=bithe.llc.ehunter.ehunter&hl=es_CO&gl=US" target={"_blank"}>
                                <img id="appGoogle" src={imgGooglePlay} alt="E-hunter" />
                            </a>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-6">
                        <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutRight'>
                        <a style={{display:'block',height:'100%'}} href="javascrtip:void(0)" title='Próximamente'>
                            <img id="appStore" src={imgAppStore} alt="E-hunter" />
                        </a>
                        </ScrollAnimation>
                    </div>
                    </div> 
                </div>
                <div className="col-lg-3"></div>
            </div>
        </Container>
    );
}

export default Aplicaciones;