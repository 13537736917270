import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/logo.png'
import './Header.css'

const Header = () => {
    return(
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-white"
                style={{ position: "fixed", width: '100%', top: 0, zIndex: 100, boxShadow: '0px 9px 9px 0px rgba(0, 0, 0, 0.1)' }}>
                <div className="container-fluid"
                    style={{ marginLeft: "40px", marginTop: "5px" }}>
                    <div className="row w-100">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-2 text-center">
                            <a className="navbar-brand" href="/">
                                <img id="logo-header" src={logo} alt='logo' />
                            </a>
                        </div>
                        <div className="col-lg-8 pt-2 flex-row-reverse">
                            <div
                                className="collapse navbar-collapse"
                                id="navbarSupportedContent"
                                style={{ marginLeft: "18px", float:'right' }}
                            >
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{ color: "#254D71", fontFamily: "Poppins", float:"right" }} >
                                <li className="nav-item" style={{ width: "70px" }}>
                                        <Link
                                            className="nav-link active"
                                            aria-current="page"
                                            to="/"
                                            >
                                            <svg style={{marginLeft: '20px'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z"/></svg>
                                        </Link>
                                    </li>
                                    <li className="nav-item" style={{ width: "70px" }}>
                                        <Link
                                            className="nav-link active"
                                            aria-current="page"
                                            to="/vision"
                                            >
                                            VISIÓN
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link active"
                                            aria-current="page"
                                            to="/sobre-nosotros"
                                            >
                                            SOBRE NOSOTROS
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link active"
                                            aria-current="page"
                                            to="/ayuda"
                                            >
                                            AYUDA
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header