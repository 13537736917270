import React from 'react'
import './FAQS.css';


const FAQS = () => {
    return(
        <div className="container" style={{marginTop:'8em', fontFamily:'poppins'}}>
            <div className="row">
                <div className="col-lg-12 text-center mb-3">
                    <b style={{fontSize:'22pt', color:'#4f4f4f'}}>Preguntas frecuentes</b>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <p>
                    Acabas de llegar al lugar correctos si tienes curiosidad de saber más de nosotros. Aquí encontraras todo lo que deseas conocer de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 text-center mb-3">
                    <b style={{fontSize:'22pt', color:'#4f4f4f'}}>Perfil de usuario <b>E-HUNTER <span style={{fontSize:'12pt'}}>APP</span></b> y tus catches</b>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Qué recomendaciones me dan a la hora de subir mis fotos de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>?</b>
                    <p>
                    Sé original, las personas quieren conocerte tal y como eres, nuestras estadísticas dicen que tendrás mejores resultados con una gran sonrisa, sin gorras y sin lentes oscuros, ¡vamos sube tu mejor foto a <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Por qué debo describirme o hacer mi biografía? </b>
                    <p>
                    Porque recuerda que no todo es el físico, es importarte mostrar quien eres, que haces, que te gusta hacer, recuerda que miles de persona esperan por ti, quieren personas afines a sus gustos e intereses y seguro obtendrás muchos catches. Para obtener información sobre qué no incluir en tu biografía, consulta nuestras principios de la comunidad <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Cómo puedo dejar de hablar o eliminar un catch?</b>
                    <p>
                    Esa persona no era lo que esperabas?, algo no salió bien con tu catch? O simplemente ya encontraste lo que buscabas y no te interesan oros catch, ¡perfecto! Tu puedes bloquear los catch haciendo click en reporte de perfiles que se encuentra en la parte superior derecha de la tarjeta de contacto y le das bloquear ese usuario, también podrás volver a desbloquearlo cuando lo creas necesario.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Qué son los catches de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>?</b>
                    <p>
                    Cuando dos usuarios se dan like presionando el icono de corazón color rosa simultáneamente o deslizando a la derecha en cualquier momento, automáticamente se producirá un catch y podrás iniciar tu conversación con tu catch.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 text-center  mb-3">
                <b style={{fontSize:'22pt', color:'#4f4f4f'}}>Todo sobre cómo funciona <b>E-HUNTER <span style={{fontSize:'12pt'}}>APP</span></b></b>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Cuánto debo pagar para descargar <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>?</b>
                    <p>
                    Nada!!, Puedes descargar de forma gratuita <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> en la App Store y Google Play Store. Con tu descarga gratis obtendrás un perfil Diamont para disfrutar durante 30 días en el cual podrás conocer y disfrutar de todas las bondades del perfil Diamont es decir y después de los 30 días tu perfil cambiara al  perfil free o básico y tendrás Las funciones básicas te permiten en tu perfil, utilizar la función de darle Like a alguien (100 unidades que se renuevan cada 12 horas) y utilizar Deslizar cuando no tengas interés en determinado usuario, tendrás dos bombas al mes para resaltar tu perfil durante 60 minutos, podrás mandar 5 fotos al mes, hacer 2 videos llamadas y mandar 1 audio por usuario, podrán usar 2 vez al mes el express hunting.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Cómo funciona <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>?</b>
                    <p>
                    La compañía Bithe LLC, diseñó un algoritmo que permite a <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> conectar perfiles de usuarios según su localización, distancia, intereses comunes y género. 
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Los perfiles de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> son reales?</b>
                    <p>
                    <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> ofrece Verificación mediante selfi para asegurarse de que la persona con la que estás hablando sea la que aparece en sus fotos. Los perfiles verificados tendrán una marca de verificación azul y te recomendamos que consigas una tú también. Hacen que todos se vean bien y ayudan a que nuestra comunidad se mantenga segura.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Puedo usar <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> en una laptop u ordenador de mesa?</b>
                    <p>
                    Estamos trabajando en ello, pronto recibirás grandes noticas de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Dónde funciona <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>?</b>
                    <p>
                    <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> es una app móvil global de citas donde encontraras una gama de opciones de encuentros que van desde una relación formal, hasta una agradable compañía para tu próximo viaje o simplemente para iniciar relaciones profesionales o de negocios.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12  text-center  mb-3">
                <b style={{fontSize:'22pt', color:'#4f4f4f'}}>Todo sobre las Funciones y Suscripciones de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b></b>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Cómo funciona <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> Diamont™?</b>
                    <p>
                    Cada usuario Diamont tendrá una insignia en su tarjeta de contacto de un diamante, tendrán la ventaja de hacer catch directo con cualquier usuario que elija, conocer tus admiradores secretos cuando te suscribes a <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> Diamont puedes salir de cacería express dos veces por día, enviar tu ubicación, dar like ilimitados, enviar fotos y audios ilimitados.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Cómo funciona <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> Boom?</b>
                    <p>
                    Cuando usas Boom puedes resaltar tu perfil en tu área durante 60 minutos y así multiplicar por mucho las posibilidades de hacer catch.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Cómo funcionan los Top boom de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>?</b>
                    <p>
                    En <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> le damos valor a tu esfuerzo y premiamos tu perfil con top boom, lo hemos diseñado como un ranking donde se muestran los perfiles más codiciados de E-HUNTER®, donde el primero del ranking es el que más like ha obtenido, los usuarios free podrán ver 10 top boom al día mientras que los usuarios Diamont podrán deslizarse por los 50 perfiles más destacados de su ubicación.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Por qué debo suscribirme <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> Diamont?</b>
                    <p>
                    Simplemente es la mejor manera de interactuar con una gama completa de funciones desbloqueadas que te harán resaltar y obtener mayores resultados.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿<b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> patrocinará encuentros personales?</b>
                    <p>
                    Aunque <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> ha se basa en una plataforma digital que ayuda a las personas de todo el mundo para que tengan sus propios encuentros ya sea de amor, placer o de negocios, se está trabajando para realizar anualmente un evento masivo con personas de diferentes partes del mundo y así incentivar los usuarios más fieles de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Puedo compartir mi historia personal en <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>?</b>
                    <p>
                    ¡Claro que sí! En <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> estamos ansiosos de conocer tu historia de amor, negocios, viajes, relaciones profesionales, solo déjanos saber tu historia que nosotros se la contaremos al mundo.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿En <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> puedo encontrar el amor de mi vida?</b>
                    <p>
                    Sabemos que la pandemia por covid-19 ha cambiado el mundo y la forma de conocer personas y estamos seguro que <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> será la plataforma ideal para conseguir el amor que buscas.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Puedo ser parte del equipo de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>?</b>
                    <p>
                    ¡Por supuesto! Puedes trabajar con nosotros, asesorarnos, realizar donaciones o colaborar de muchas maneras, Escríbenos al siguiente correo: hi@e-hunterapp.com y dinos de que forma puedes aportar a nuestra compañía.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Puedo ser socio de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>?</b>
                    <p>
                    Si tienes alguna propuesta y piensas que puede ser interesante y quieres invertir en el campo de las tecnologías, redes sociales y que tu aporte será de gran valor, no dudes en escribirnos al correo: hi@e-hunterapp.com indicando en el asunto: quiero ser socio <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>.
                    </p>
                </div>
            </div>
    </div>
    )
}

export default FAQS