import React from 'react'

const Uso = () => {
    return(
        <div className="container" style={{marginTop:'8em', fontFamily:'poppins'}}>
            <div className="row">
                <div className="col-lg-12 text-center mb-3">
                    <b style={{fontSize:'22pt', color:'#4f4f4f'}}>Términos de uso</b>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <b>Consejos de seguridad para citas</b>
                    <p style={{textAlign:'justify'}}>
                    Tu experiencia en <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> será algo maravilloso, pues aquí encontraras una gama de opciones de encuentros que van desde una relación formal, hasta una agradable compañía para tu próximo viaje o simplemente para iniciar relaciones profesionales o de negocios, pero, recuerda que no todas las personas traen buenas intenciones y que tú debes estar alerta siempre y puedes mantenerte a salvo durante tu experiencia <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>, aplicando los siguientes consejos de seguridad:
                    <br/>
                    Seguridad en línea
                    <ul>
                        <li><b>Nunca compartas información financiera</b><br/>Desconfía siempre que alguien te pida tus datos personales o cualquier otra información que puedan usar para acceder a tu información financiera, en ningún caso envíes a nadie tus datos financieros, ni claves, ni preguntas de seguridad; si alguien te pide esta información o sospechas que quieren robar tu información financiera notifícanoslo inmediatamente.</li>
                        <li><b>Nunca envíes dinero</b><br/> Nunca envíes dinero a nadie, los delincuentes siempre engañan a sus víctimas o estafan a personas que dicen estar atrapada, secuestradas, en problemas o que tienen grandes sumas de dinero en otro lugar y necesitan dinero para moverlo, lo más seguro es que después de enviarle dinero alguna persona esta desaparezca de tu vida y no vuelvas a saber más nunca del dinero ni de la persona, si alguien te pide dinero notifícanoslo inmediatamente.</li>
                        <li><b>Protege tu información personal</b><br/>Tu información personal puede ser muy sensible y puede ser una gran herramienta para personas inescrupulosas, te recomendamos nunca dar tu dirección de vivienda o trabajo, ni tus rutinas diarias es decir que rutas tomas a qué hora sales al trabajo o la escuela o al gimnasio, no compartas tu identificación, placas de vehículo y ante todo no compartas información de tus hijos ni de otros menores de edad referente a sus nombres, escuelas, horarios escuela deportiva si alguien te pide esta información notifícanoslo inmediatamente.</li>
                        <li><b>Mantén tus conversaciones en E-Hunter E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b><br/> <b>E-Hunter E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> te ofrece la posibilidad de chatear ilimitadamente, enviar fotos, audios y hacer video chat, por ende, si alguien te pide ir otra plataforma de chat, correos electrónicos, MSM o llamadas directas puede ser que no tenga buenas intenciones, te recomendamos mantenerte en <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> hasta que hayas verificado la identidad y las intenciones de tu contacto.</li>
                        <li><b>Desconfía siempre de comportamientos sospechosos y de relaciones en otros países </b><br/>Existen muchos estafadores prometiendo amor y relaciones estables aun sin conocerte en persona  y otros diciendo que se acaban de accidentar o están atrapados en otro país o están en peligro y que necesitan que les envíe dinero, ten mucho cuidado son estrategias que utilizan personas para estafar  Pide una llamada de video o llamada de audio para comprobar la identidad, muchas personas inescrupulosas no te dejan conocer personalmente ni que le hagas llamadas ni videos que puedan comprobar su identidad y dicen estar en otra ubicación para engañarte.</li>
                        <li>
                            <b>Reporta los comportamientos sospechosos, ofensivos o que no cumplan con nuestros principios de comunidad</b><br/>
                            Si sospechas de alguien que crees tiene intenciones de estafar, extorsionar, o cuando sientas que alguien ha violado nuestros términos de uso háznoslo saber inmediatamente, no dudes en bloquear y denunciar estos perfiles
                            Las principales violaciones a los principios de comunidad de <b>E-Hunter <span style={{fontSize:'8pt'}}>APP</span></b> son:
                            <ol>
                                <li>Pornografía infantil</li>
                                <li>Inducción a la pornografía o prostitución.</li>
                                <li>Solicitud de dinero o donaciones.</li>
                                <li>Racismo, xenofobia y homofobia.</li>
                                <li>Suplantación de personas y perfiles falsos.</li>
                                <li>Ofertas de servicios, enlaces a sitios web, intento de vender productos o servicios, ofertas, correos masivos, spam o no deseado.</li>
                            </ol>
                            Notifica cualquier comportamiento que consideres sospechoso en cualquier perfil o ventana de mensaje o pulsa aquí Para obtener más información, consulta nuestros principios de comunidad de <b>E-Hunter <span style={{fontSize:'8pt'}}>APP</span></b>.
                        </li>
                        <li><b>Protege tu cuenta E-Hunter <span style={{fontSize:'8pt'}}>APP</span></b><br/>Al crear tu perfil de <b>E-Hunter <span style={{fontSize:'8pt'}}>APP</span></b> debes tener la precaución de crear una contraseña segura y tener especial cuidado al usar computadoras públicas o compartidas o de iniciar sección en dispositivos móviles de terceras personas (pueden suplantar tu identidad y tener problemas legales) E-HUNTER app nunca te enviará un correo electrónico pidiéndote tu nombre de usuario y contraseña. Si recibes un correo electrónico solicitando información de la cuenta, denúncialo de inmediato.</li>
                    </ul>
                    </p>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12 text-center mb-3">
                    <b style={{fontSize:'22pt', color:'#4f4f4f'}}>DE LOS ENCUENTROS PERSONALES</b>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12">
                    <p>
                    <ul>
                        <li><b>Sin afanes </b><br/>Procura conocer bien la otra persona antes de acceder a verte con ella, utiliza filtros de preguntas y vuelve a preguntar a ver si hay contradicciones en las respuestas, primero haz video llamada o llamada de audio, si el contacto no esconde nada no tendrá problemas en mostrarse, identifica señales de alerta y si descubres algo que te inquieta da cancela tu catch con ese contacto y repórtalo.</li>
                        <li><b>Siempre lugares públicos</b><br/> Cuando por fin accedas a conocer un contacto en persona procura siempre un lugar público y muy concurrido de personas y en horarios de día, si tu contacto insiste en verte en su casa u en otro lugar privado y alejado de la gente debes encender las alarmas y da por terminada la cita ahí mismo, cancela tu catch con ese contacto y repórtalo.</li>
                        <li><b>Avisa a tus padres y amigos de tus planes </b><br/>Siempre durante las primeras citas dile a tus padres, familiares o amigos en qué lugar y con quien estarás y no permitas que el contacto te pida abandonar ese lugar para ir a otro sitio, recuerda llevar siempre tu dispositivo móvil con suficiente batería y saldo para llamar o escribir ante una emergencia.</li>
                        <li><b>Controla tu transporte </b>Es importante que tengas bajo control la forma en que puedes llegar e irte de Nunca dependas de tu contacto para llegar a la cita y mucho menos para regresarte, recuerda que si la cita sale mal debes tener solucionado tu transporte de regreso (si no tienes transporte propio puedes usar taxis, apps de transporte de pasajero urbano o tener un contacto a la mano para que vaya a recogerte).</li>
                        <li><b>Las drogas y el alcohol</b> Si tu contacto te presiona para consumir alcohol o drogas da por terminada tu cita, reporta el perfil y cancela tu catch con ese usuario, si consumes drogas o alcohol bajo tu propia voluntad debes saber de los efectos que tengan sobre ti y de la forma de ver la realidad y evaluar el peligro en una cita.<br/>
                        Nunca descuides tus bebidas a manos de tu acompañante y verifica que siempre sean servidas por el mesero ya que pueden aplicarte cualquier sustancia que te desinhiba, o facilite las relaciones sexuales o la entrega de tus datos personales, dinero e información financiera.
                        </li>
                        <li><b>Tus objetos personales</b><br/> No descuides tus objetos personales tales como bolso, billetera, dispositivo móvil, etc. ya que puedes ser víctima de hurto.</li>
                        <li><b>Terminar una cita que va mal</b> <br/>Algo te hace sentir desconfiado/a?, algo salió mal?, te sientes en peligro? Abandona la cita sin decir nada y vete o pide ayuda alguna persona cercana o realiza una llamada de emergencia.</li>
                        <li>
                            <b>Viajes LGBTQ+</b><br/>
                            ¡Ten cuidado al viajar!<br/>
                            En <b>E-Hunter <span style={{fontSize:'8pt'}}>APP</span></b> reconocemos y creemos en la importancia de aceptar e incluir todas las identidades de género y todas las orientaciones sexuales, pero la realidad es que no hay país del mundo que esté exento de riesgos para la comunidad LGBTQ+, y en ciertos países hay leyes específicas en contra de dicha comunidad.<br/>
                            Comprueba las leyes de los países a los que vayas a viajar e investiga qué tipo de protección legal, si es que existe alguna, tendrás a tu disposición dependiendo de tu orientación sexual. En caso de que te encuentres en un territorio peligroso, te sugerimos que desactives la opción “Mostrarme en <b>E-Hunter <span style={{fontSize:'8pt'}}>APP</span></b>”, que podrás encontrar en la página de Configuración.<br/>
                            Si has añadido una orientación sexual a tu perfil y eliges que esta se muestre en <b>E-Hunter <span style={{fontSize:'8pt'}}>APP</span></b>, haremos que no sea visible en tu perfil hasta que abandones el área conflictiva.
                            Visita la web de ILGA World para conocer las leyes más recientes sobre orientación sexual según cada país.<br/>
                            <i>Fuente: ILGA World, actualizado en marzo de 2019</i>
                        </li>
                    </ul>
                    </p>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12 text-center mb-3">
                    <b style={{fontSize:'22pt', color:'#4f4f4f'}}>Salud sexual y consentimiento</b>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12">
                    <p>
                    <ul>
                        <li><b>Usa preservativos </b><br/>Los condones son fáciles de conseguir, usar y ayudan a evitar tanto los embarazos como las ETS. Los condones incluso pueden mejorar las relaciones sexuales, de verdad; son los únicos métodos anticonceptivos que también ayudan a prevenir el contagio de infecciones de transmisión sexual, incluyendo como el VIH, VPH, la clamidia y la gonorrea. Incluso si estás usando método anticonceptivo distinto para evitar embarazos.</li>
                        <li><b>Rompe la cadena de contagios</b><br/> Muchas de las enfermedades de transmisión sexual no muestran síntomas y no es nada bueno contraer este tipo de enfermedad sin ser consciente de ello. Si eres portador de una enfermedad de transmisión sexual y eres consciente de ello, ve al médico, usa obligatoriamente el preservativo y debes tener especial cuidado de no infectar a más personas, si desconoces tener una ETS de todas formas ve al médico, usa o exige siempre el condón.</li>
                        <li><b>Consentimiento</b><br/>Toda actividad sexual debe iniciarse de forma consentida y debería incluir comprobaciones continuas con la pareja. La comunicación verbal puede ayudarte a ti y a tu pareja a mantener el respeto por vuestros respectivos límites. El consentimiento se puede retirar en cualquier momento; el sexo no es algo que se le deba a nadie. No sigas adelante si tu pareja parece inseguro/a o incómodo/a, o si no es capaz de mostrar consentimiento debido al consumo de alcohol o drogas. Lee más al respecto aquí.</li>
                    </ul>
                    </p>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12 text-center mb-3">
                    <b style={{fontSize:'22pt', color:'#4f4f4f'}}>Recursos de ayuda, soporte o asesoramiento</b>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12">
                    <p>
                    Recuerda: incluso si sigues estos consejos, no existe ningún método perfecto para la reducción de riesgos. Si tienes una experiencia negativa, no lo consideres culpa tuya y sé consciente de que tienes ayuda a tu disposición. Denuncia cualquier incidente a <b>E-Hunter <span style={{fontSize:'8pt'}}>APP</span></b> y piensa en ponerte contacto con nosotros en cualquiera de los recursos siguientes. <br/>
                    <b>Si sientes que estás en peligro inmediato o necesitas ayuda de emergencia, llama al 911 (en EE. UU. O Canadá) o a las fuerzas policiales locales.</b>
                    <ul>
                        <li><b>Línea directa de asalto sexual RAINN </b><br/>1-800-656-HOPE (4673) | online.rainn.org | www.rainn.org</li>
                        <li><b>Planned Parenthood. </b><br/>1-800-230-7526 | www.plannedparenthood.org</li>
                        <li><b>National Domestic Violence Hotline. </b><br/>1-800-799-SAFE (7233) o 1-800-787-3224 | www.thehotline.org</li>
                        <li><b>National Human Trafficking Hotline. </b><br/>1-888-373-7888 o envía un SMS a 233733 | www.humantraffickinghotline.org</li>
                        <li><b>Centro Nacional de Recursos sobre Violencia Sexual. </b><br/>1-877-739-3895 | www.nsvrc.org</li>
                        <li><b>Centro Nacional de Niños Desaparecidos y Explotados. </b><br/>1-800-THE-LOST (843-5678) | www.cybertipline.com</li>
                        <li><b>Iniciativa Cibernética sobre Derechos Civiles. </b><br/>1-844-878-2274 | www.cybercivilrights.org</li>
                        <li><b>VictimConnect: Centro de Recursos para Víctimas de Crímenes. </b><br/>1-855-4VICTIM (855-484-2846) | www.victimconnect.org</li>
                        <li><b>Centro de Quejas sobre Crímenes de Internet del FBI. </b><br/>www.ic3.gov</li>
                        <li><b>Centro de Ayuda Nacional LGBT. </b><br/>1-888-843-4564 | www.glbtnationalhelpcenter.org</li>
                        <li><b>Trans Lifeline. </b><br/>1-877-565-8860 (EE. UU.) o 1-877-330-6366 (Canadá) | www.translifeline.org</li>
                    </ul>
                    Si te encuentras fuera de los EE. UU.:
                    <ul>
                        <li>Haz clic aquí para descubrir más recursos en muchos de los países en que operamos.</li>
                        <li>Haz clic aquí para obtener información sobre las leyes de orientación sexual de la Asociación de Intersexuales, Transexuales, Bisexuales, Gais y Lesbianas (ILGA).</li>
                    </ul>
                    </p>
                </div>
            </div>          

    </div>
    )
}

export default Uso