import React from 'react'
import styled from "styled-components";
import imgMujerEHunter from '../../assets/img/Mujer_02_E-Hunter.png'
import './Explora.css'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

const Container = styled.div`
    display: 'flex';
    width: 100%;
    justify-content:center;
    align-items:center;
    padding: 20px 0px;
    background: rgb(215,21,172);
    background: linear-gradient(360deg, rgba(215,21,172,1) 0%, rgba(215,21,172,1) 20%, rgba(182,38,226,1) 100%);
`;

const Explora = () => {
    return ( 
        <Container>
            <div id="explora" className="row mt-5">
                <div className="col-lg-6 text-center">
                    <ScrollAnimation animateIn='wobble' initiallyVisible={true}>
                        <img src={imgMujerEHunter} style={{width:'70%'}} alt="E-hunter" />
                    </ScrollAnimation>
                </div>
                <div className="col-lg-6 text-center text-white">
                    <div className="row">
                        <ScrollAnimation animateIn='flipInY' animateOut='flipOutY'>
                            <div className="col-lg-8">
                                <h1 id="explora-title" className="mb-5" style={{fontSize:'45pt',marginTop:'40%'}}>Explora varios intereses</h1>
                            <p style={{fontSize:'19pt', padding:'20px'}}>
                            <b>E-HUNTER</b> hemos diseñado un algoritmo a tu medida que te acercará a esas personas que comparten tus mismos intereses y gustos
                            </p>
                            </div>
                            <div className="col-lg-4"></div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Explora;