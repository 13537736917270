import React from 'react'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import construction from '../../assets/img/construction.jpg'

const Servicios = () => {
    return(
        <div className="container" style={{marginTop:'8em', fontFamily:'poppins'}}>
            <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                <div className="row">
                    <div className="col-lg-12 text-center mb-3">
                        {/* <b style={{fontSize:'22pt', color:'#4f4f4f'}}>Servicios</b> */}
                    </div>
                </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                <div className="row">
                    <div className="col-lg-12">
                        <img src={construction} style={{width:'100%'}} />
                    </div>
                </div> 
            </ScrollAnimation> 
    </div>
    )
}

export default Servicios