import React from 'react'

const Cookies = () => {
    return(
        <div className="container" style={{marginTop:'8em', fontFamily:'poppins'}}>
            <div className="row">
                <div className="col-lg-12 text-center mb-5">
                    <b style={{fontSize:'22pt', color:'#4f4f4f'}}>Políticas de cookies <b>E-HUNTER <span style={{fontSize:'12pt'}}>APP</span></b></b>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <b>Política de cookies Contenido</b>
                    <p>
                    Esta web utiliza cookies propias y de terceros para ofrecerle una mejor experiencia y servicio. Al navegar o utilizar nuestros servicios acepta el uso que hacemos de las cookies. Sin embargo, tiene la opción de impedir la generación de cookies y la eliminación de las mismas mediante la selección de la correspondiente opción en su Navegador. En caso de bloquear el uso de cookies en su navegador es posible que algunos servicios o funcionalidades de la página Web no estén disponibles.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Qué es una cookie?</b>
                    <p>
                    Las cookies constituyen una herramienta empleada por los servidores Web para almacenar y recuperar información acerca de sus visitantes. Es un identificador único en forma de fichero de texto que se remite al dispositivo del usuario para registrar información, permitiendo así mejorar la calidad y seguridad de la página web. Poseen una fecha de caducidad a partir de la cual dejan de ser operativas.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>Utilidad de las cookies en mi página web</b>
                    <p>
                    Utilizamos cookies para facilitar la navegación por la web y para obtener una mayor eficacia y personalización de los servicios que le ofrecemos. Las cookies empleadas se asocian únicamente con un Usuario anónimo y su dispositivo, no proporcionan referencias que permitan obtener sus datos personales ni incluir virus en el mismo. Tampoco se pueden leer las cookies implantadas en su dispositivo desde otros servidores. La información que analizamos haciendo uso de las cookies son la dirección de protocolo de internet (IP) empleada para conectar tu ordenador a internet, tipo y versión de navegador, sistema operativo y plataforma utilizada para la conexión y la fecha y la hora de dicha actividad.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>Cookies empleadas</b>
                    <p>
                    Utilizamos cookies permanentes que se almacenan en el dispositivo del usuario y que permiten recuperar la información de la navegación en la siguiente visita. Tienen fecha de caducidad. Estas cookies nos permiten personalizar sus visitas, a través de sus preferencias (por ejemplo, país e idioma).
                    <br/>
                    También utilizamos cookies de terceros que permiten gestionar y mejorar los servicios ofrecidos. Como, por ejemplo, los servicios estadísticos de Google Analytics.


                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>Cookies de análisis estadístico y de registro</b>
                    <p>
                    Utilizamos cookies permanentes que se almacenan en el dispositivo del usuario y que permiten recuperar la información de la navegación en la siguiente visita. Tienen fecha de caducidad. Estas cookies nos permiten personalizar sus visitas, a través de sus preferencias (por ejemplo, país e idioma).
                    <br/>
                    También utilizamos cookies de terceros que permiten gestionar y mejorar los servicios ofrecidos. Como, por ejemplo, los servicios estadísticos de Google Analytics. A continuación, recogemos de forma esquematizada las cookies anteriormente descritas y utilizadas en la página web:
                    <table border="1">
                        <tr>
                            <td style={{border:'1px solid black'}}><b>COOKIES</b></td>
                            <td style={{border:'1px solid black'}}><b>DESCRIPCIÓN</b></td>
                        </tr>
                        <tr>
                            <td style={{border:'1px solid black'}}>DE LAS ESENCIALES EN SITIOS WEB</td>
                            <td style={{border:'1px solid black'}}>Se usan estas cookies para mejorar nuestra página web y proporcionar servicios y funciones a los usuarios. Usted puede limitar o desactivar el uso de cookies a través de su navegador de Internet. Sin embargo, en este caso, usted podría no ser capaz de acceder a ciertas características de la página web.</td>
                        </tr>
                        <tr>
                            <td style={{border:'1px solid black'}}>DE ANALÍTICA</td>
                            <td style={{border:'1px solid black'}}>Este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.</td>
                        </tr>
                        <tr>
                            <td style={{border:'1px solid black'}}>DE PUBLICIDAD</td>
                            <td style={{border:'1px solid black'}}>Permite desarrollar un perfil específico para mostrar publicidad en función de este.</td>
                        </tr>
                        <tr>
                            <td style={{border:'1px solid black'}}>DE REDES SOCIALES</td>
                            <td>Las utilizamos para que los visitantes puedan interactuar con el contenido de diferentes redes sociales (Facebook, Twitter, Instagram, tiktok, entre otras, etc.) y que se generan únicamente para los usuarios de dichas redes sociales. Dichas También se pueden usar con fines publicitarios.</td>
                        </tr>
                    </table>
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Qué ocurre si se deshabilitan las cookies?</b>
                    <p>
                    En el caso de bloquear o no aceptar la instalación de cookies es posible que ciertos servicios ofrecidos por nuestro sitio web que necesitan su uso queden deshabilitados y, por lo tanto, no estén disponibles para usted por lo que no podrá aprovechar por completo todo lo que nuestras webs y aplicaciones le ofrecen. Es posible también que la calidad de funcionamiento de la página web pueda disminuir.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>Actualizaciones en la Política de Cookies</b>
                    <p>
                    Nuestra página web puede modificar esta Política de Cookies en función de exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por las diferentes Agencias de Protección de Datos, por lo que se aconseja a los usuarios que la visiten periódicamente.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>Como utiliza google las cookies</b>
                    <p>
                    cuando navegas por nuestro sitio web y cuando usas los servicios que utilizan las API cookies de Google™ Maps, usted está dando consentimiento para almacenamiento y para la recoger de tales cookies en su dispositivo y a que Google pueda acceder, usar y compartir los datos recolectados por estas.
                    Google™ gestiona la información y sus opciones en relación con las API cookies de Google™ Maps a través de una interfaz separada de la que provee su navegador. Lo invitamos a que estudie todo lo relacionado respecto al uso de las cookies por google en el siguiente enlace <a href="https://www.google.com/policies/technologies/cookies/" target="_blank">https://www.google.com/policies/technologies/cookies/</a>.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <b>¿Cómo contactarnos?</b>
                    <p>
                    Si tiene preguntas sobre esta Política de cookies, puede contactarnos de la siguiente manera:<br/> En línea: <a href="javascript:void(0)">aquí</a>
                    </p>
                </div>
            </div>
    </div>
    )
}

export default Cookies