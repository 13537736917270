import React from 'react'
import styled from "styled-components";
import imgHombreEHunter from '../../assets/img/Hombre_E-Hunter.png'
import imgMujerEHunter from '../../assets/img/Mujer_E-Hunter.png'
import imgIcon from '../../assets/img/Icono_App_E-Hunter.png'
import './Conecta.css'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

const Container = styled.div`
    display: 'flex';
    width: 100%;
    /* background: #00a07e; */
    justify-content:center;
    align-items:center;
    background-size: contain;
    padding: 20px 0px;
    
`;

const Conecta = () => {
    return ( 
        
            <Container id="conecta">
                <img src={imgIcon} style={{width:'5%',marginLeft:'40px',zIndex:'99999'}} alt="E-hunter" />
                <ScrollAnimation duration={3} animateIn='hinge' initiallyVisible={true}>
                    <img id="conecta-imageicon" src={imgIcon} style={{width:'5%',marginLeft:'40px',zIndex:'99999'}} alt="E-hunter" />
                </ScrollAnimation>
                <div  className="row">
                    <div className="col-lg-6 text-center">
                        <ScrollAnimation animateIn='flipInY' animateOut='flipOutY'>
                            <h1 id="conecta-title" className="mb-5" style={{color:'#FF006A', marginTop:'25%', fontWeight:'bold'}}>CONECTA CON OTROS</h1>
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8">
                                <p style={{padding:'20px'}}>
                                    <b>E-HUNTER</b>, será tu compañero de viaje en todos los rincones del planeta, podrás conocer personas en cada lugar que visites, ya sea para tener una relación de largo plazo, para encontrar nuevos amigos y descubrir otras culturas o si lo que buscas es una relación profesional y/o de negocios, ¡felicidades has llegado al lugar ideal!
                                </p>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-6 text-center">
                        <ScrollAnimation animateIn="fadeIn">
                            <img id="conecta-imagemujerE" src={imgMujerEHunter} alt="E-hunter"  />
                            <img id="conecta-imagehombreE" src={imgHombreEHunter} alt="E-hunter"  />
                        </ScrollAnimation>
                    </div>
                </div>
            </Container>
        
        
    );
}

export default Conecta;