import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Header from './container/Header'
import Cookies from './container/Cookies'
import Footer from './container/Footer'
import FAQS from './container/FAQS'
import Uso from './container/Uso'
import Privacidad from './container/Privacidad'
import Slider from './container/Slider'
import Conecta from './container/Conecta'
import Explora from './container/Explora'
import NuestraApp from './container/NuestraApp'
import Express from './container/Express'
import Aplicaciones from './container/Aplicaciones'
//import Contactanos from './container/Contactanos'
import Nosotros from './container/Nosotros'
import Vision from './container/Vision'
import Services from './container/Servicios'
import ScrollToTop from './components/ScrollToUp';
import TerminosUsoCompleto from './container/TerminosUsoCompleto'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route exact path="/">
          <Slider />
          <Conecta />
          <Explora />
          <Express />
          <NuestraApp />
          <Aplicaciones />
        </Route>

        <Route exact path="/politicas-de-cookies">
          <Cookies />
          <Aplicaciones />
        </Route>

        <Route exact path="/faqs">
          <FAQS />
          <Aplicaciones />
          {/* <Contactanos /> */}
        </Route>

        <Route exact path="/terminos-de-uso">
          <Uso />
          <Aplicaciones />
        </Route>

        <Route exact path="/terminos-de-uso-completo">
          <TerminosUsoCompleto />
          <Aplicaciones />
        </Route>

        <Route exact path="/terminos-de-privacidad">
          <Privacidad />
          <Aplicaciones />
        </Route>

        <Route exact path="/sobre-nosotros">
          <Nosotros />
          <Aplicaciones />
        </Route>

        <Route exact path="/vision">
          <Vision />
          <Aplicaciones />
        </Route>

        <Route exact path="/services">
          <Services />
          <Aplicaciones />
          {/* <Contactanos /> */}
        </Route>

      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
