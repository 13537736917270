import React from 'react'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';


const Vision = () => {
    return(
        <div className="container" style={{marginTop:'8em', fontFamily:'poppins'}}>
            <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                <div className="row">
                    <div className="col-lg-12 text-center mb-3">
                        <b style={{fontSize:'22pt', color:'#4f4f4f'}}>Visión</b>
                    </div>
                </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                <div className="row">
                    <div className="col-lg-12">
                        <p>
                        <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>  es la app de citas que revolucionó el concepto tradicional de las plataformas en línea para ligar, que se caracteriza por su dinamismo e innovación, la cual se proyecta como una de las aplicaciones para encontrar pareja más usada a nivel global, con <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> los sueños y fantasías de millones de usuarios son convertidos en realidad
                        </p>
                    </div>
                </div> 
            </ScrollAnimation> 
    </div>
    )
}

export default Vision