import React from 'react'
import logo from '../../assets/img/logo-white.png'
import {Link} from 'react-router-dom'
import moment from 'moment'
import './Footer.css';
import twitter from '../../assets/img/redes/twitter_white.png'
import facebook from '../../assets/img/redes/facebook_white.png'
import instagram from '../../assets/img/redes/instagram_white.png'

const Footer = () => {
    return(
        <div style={{background:'#AE2AEF',           
            color:'white',fontFamily:'poppins'
        }}>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-12 text-center mt-5">
                        <img src={logo} alt="E-Hunter" style={{width:'20%'}} />
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <p>Asegurese de mirar nuestros términos de uso y políticas de privacidad</p>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                        <div className="row mt-5">
                            
                            <div className="col-lg-7">
                                <ul>
                                    <li style={{display:'inline',marginRight:'20px'}}><Link to="/terminos-de-uso-completo">Términos de uso</Link></li>
                                    <li style={{display:'inline',marginRight:'20px'}}><Link to="/terminos-de-privacidad">Políticas de privacidad</Link></li>
                                    <li style={{display:'inline',marginRight:'20px'}}><Link to="/politicas-de-cookies">Términos de cookies</Link></li>
                                    <li style={{display:'inline',marginRight:'20px'}}><Link to="/faqs">FAQS</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-5">
                                <ul>
                                    <li style={{display:'inline',marginRight:'20px'}}>Síguenos:</li>
                                    <li style={{display:'inline',marginRight:'20px'}}><Link to="#"><img src={twitter} alt="Twitter" style={{width:'5%'}} /></Link></li>
                                    <li style={{display:'inline',marginRight:'20px'}}><Link to="#"><img src={facebook} alt="Facebook" style={{width:'5%'}} /></Link></li>
                                    <li style={{display:'inline',marginRight:'20px'}}><Link to="#"><img src={instagram} alt="Instagram" style={{width:'5%'}} /></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <p>Copyright &copy; { moment().format('YYYY') } e-hunterapp.com - Todos los derechos reservados - Bithe S.A.S., Bithe LLC</p>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer