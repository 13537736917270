import React from 'react'
import styled from "styled-components";
import logo from '../../assets/img/logo-white.png'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import './NuestraApp.css'

const Container = styled.div`
    display: 'flex';
    width: 100%;
    justify-content:center;
    align-items:center;
    padding: 20px 0px;
    background: rgb(182,38,226);
    background: linear-gradient(360deg, rgba(182,38,226,1) 0%, rgba(215,21,172,1) 70%, rgba(215,21,172,1) 100%);
`;

const NuestraApp = () => {
    return ( 
        <Container>
            <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                <div className="row mt-5 text-white">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8 text-center">
                        <img src={logo} style={{width:'25%'}} alt="E-hunter" />
                        <h2 className="mt-5" style={{fontWeight:'bold'}}>¿Por qué elegir nuestra app de citas?</h2>
                        <p style={{fontSize:'14pt'}} id="nuestraAppTexto">
                            <b>E-hunter</b> se proyecta como la app de citas más popular de estados unidos y latino américa y una de las más usadas a nivel mundial, superando por mucho a otras aplicaciones de similares por su versatilidad, facilidad de usar, diseños mejorados, mejor lenguaje de programación y características únicas que hacen de <b>E-HUNTER</b> una verdadera novedad donde nuestro equipo de profesionales luchan  día a día para que no sientas esas frustraciones que te ofrecen otras plataformas y nos esforzamos para siempre vivas una experiencia renovada, dinámica y con mejores y selectos perfiles de usuarios.
                        </p>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </ScrollAnimation>
        </Container>
    );
}

export default NuestraApp;