import React from 'react'
import { Link } from 'react-router-dom'

const Uso = () => {
    return(
        <div className="container" style={{marginTop:'8em', fontFamily:'poppins'}}>
            <div className="row">
                <div className="col-lg-12 text-center mb-5">
                    <b style={{fontSize:'22pt', color:'#4f4f4f'}}>COMPROMISO DE PRIVACIDAD CON EL USUARIO</b>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <p>
                    En <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>, mantenerte seguro y darles privacidad a tus datos en lo más importante para nosotros. Diseñamos nuestros productos basados en el pilar fundamental que es la protección de la privacidad de tu información para que te sientas seguro con nuestros productos y puedas disfrutar espontáneamente en todo momento de nuestra plataforma.
                    <br/>
                    Agradecemos la confianza que has depositado en nosotros y queremos retribuirte brindándote una plataforma segura y darte la seguridad que tu información está en las mejores manos.
                    </p>
                </div>
            </div>    

            <div className="row">
                <div className="col-lg-12">
                    <p>
                    <b>Para <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>, tu privacidad no es un juego</b>. Tu seguridad ha sido diseñada por expertos del área legal, seguridad informática, ingeniería de sistemas y programadores para asegurarnos de que tu privacidad no sea violada en ninguna de nuestras acciones ni por terceros.
                    </p>
                </div>
            </div>        

            <div className="row">
                <div className="col-lg-12">
                    <p>
                    Aquí prevale el esfuerzo continuo por la honestidad y transparencia del procesamiento, almacenamiento y uso de tus datos. En <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> hemos escrito nuestra política de privacidad y sus documentos en cuestión de tal manera que su lenguaje sea sencillo. Vamos anímate ¡te invitamos a leer detalladamente nuestras políticas de privacidad!
                    </p>
                </div>
            </div>    

            <div className="row">
                <div className="col-lg-12">
                    <p>
                    <b>Un reto permanente mantener tu información segura </b> 
                    Tenemos profesionales dedicados exclusivamente a mantener tus datos seguros y protegidos. 
                    </p>
                </div>
            </div> 

            <div className="row">
                <div className="col-lg-12 text-center mb-5">
                    <b style={{fontSize:'22pt', color:'#4f4f4f'}}>Política de privacidad</b>
                </div>
            </div> 

            <div className="row">
                <div className="col-lg-12">
                    <p>
                    Bienvenido a la Política de privacidad de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>. Es importante que puedas dedicarle unos minutos y la leas.
                    Queremos darte a conocer nuestra política de privacidad y nuevamente agradecerte por la confianza que has puesto en nosotros, queremos explicarte cual es la información que recopilamos, de donde la recopilamos y como la usamos. Esta Política describe nuestras prácticas de privacidad con un vocabulario fácil, evitando lenguaje legal y técnico difícil de entender.
                    Esta Política de privacidad entrará en vigor el 10 de junio de 2021. 
                    </p>
                </div>
            </div>     

            <div className="row">
                <div className="col-lg-12">
                    <p>
                    <b>FECHA DE ENTRADA EN VIGOR:</b> 10 de junio de 2021.<br/>
                    <ul>
                        <li><a href="#1" style={{color: '#AE2AEF'}}>Quiénes son los responsables de controlar tus datos?</a></li>
                        <li><a href="#2" style={{color: '#AE2AEF'}}>Cuál es la cobertura de esta política de privacidad?</a></li>
                        <li><a href="#3" style={{color: '#AE2AEF'}}>Información que tu nos entregas</a></li>
                        <li><a href="#4" style={{color: '#AE2AEF'}}>Cookies y otras tecnologías de recopilación de datos similares</a></li>
                        <li><a href="#5" style={{color: '#AE2AEF'}}>Cómo procesamos tu información?</a></li>
                        <li><a href="#6" style={{color: '#AE2AEF'}}>Cómo compartimos la información</a></li>
                        <li><a href="#7" style={{color: '#AE2AEF'}}>Transferencias de datos transfronterizas</a></li>
                        <li><a href="#8" style={{color: '#AE2AEF'}}>Tus derechos</a></li>
                        <li><a href="#9" style={{color: '#AE2AEF'}}>Residentes en California</a></li>
                        <li><a href="#10" style={{color: '#AE2AEF'}}>Cómo protegemos tu información personal</a></li>
                        <li><a href="#11" style={{color: '#AE2AEF'}}>Cuánto tiempo conservamos tu información</a></li>
                        <li><a href="#12" style={{color: '#AE2AEF'}}>Privacidad de menores</a></li>
                        <li><a href="#13" style={{color: '#AE2AEF'}}>Cambios en la política de privacidad</a></li>
                        <li><a href="#14" style={{color: '#AE2AEF'}}>Cómo ponerse en contacto con nosotros</a></li>
                    </ul>
                    </p>
                </div>
            </div> 

            <div className="row" id="1">
                <div className="col-lg-12">
                    <b>1. Quiénes son los responsables de controlar tus datos?</b>
                    <p>
                    En caso de que vivas en Colombia o algún país de América central o américa del sur, la empresa responsable de tu información bajo esta Política de privacidad (el "controlador de datos") será:<br/>
                    BITHE SAS (Building, investment, technology, health and environment), creador de la marca <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b><br/>
                    Barranquilla, Colombia<br/>
                    Carrera 43 n 72 – 192 oficina 403 edificio Aloha.<br/>
                    Correo: hi@e-hunterapp.com<br/>
                    En caso de que vivas en Estados unidos, la empresa responsable de tu información bajo esta Política de privacidad (el "controlador de datos") será:<br/>
                    BITHE. LLC (Building, investment, technology, health and environment), creador de la marca <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b><br/>
                    Estado de la Florida.
                    6960NW 186 ST APTO 322 hialeah florida 33002.<br/>
                    Correo: hi@e-hunterapp.com
                    </p>
                </div>
            </div>   

            <div className="row">
                <div className="col-lg-12">
                    <b>2. Cuál es la cobertura de esta política de privacidad?</b>
                    <p>
                    Esta Política de privacidad se aplica a las páginas web, apps, eventos y otros servicios de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>. Y todos los servicios proporcionados por <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> están cobijados por esta misma política.<br/>
                    En caso que algún producto o servicio de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> requiera una política especial, este será cubierto por dicha política y no por esta que se describe en este documento.
                    </p>
                </div>
            </div>    

            <div className="row">
                <div className="col-lg-12">
                    <b>3. cuál es la información que recogemos?</b>
                    <p>
                    Tenemos que aclarar que para ayudarte a evolucionar enlaces significativos (CATCH) necesitamos un tener acceso a una parte de tu información, tales como: los datos básicos de tu perfil que tú mismo creas y alimentas y las preferencias de los usuarios con que te gustaría enlazar o conocer. De igual manera recogemos información que se genera cuando usas cualquiera de nuestros servicios e inicias sesión así como información de terceros, como cuándo accedes a nuestros servicios a través de una cuenta de Facebook (por citar un ejemplo). 
                    <br/>
                    <b>Información que tú nos entregas</b>
                    En el momento que creas un perfil en <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>, te registras, activas tus preferencias y gustos y entras a nuestra plataforma, eres tú mismo el que nos brindas cierto tipo de información. 
                    Nos referimos a este tipo de información:
                    <ul>
                        <li>Cuando crees una cuenta, como mínimo nos tienes que proporcionar tus credenciales para iniciar sesión, así como algunos detalles básicos necesarios para que el servicio funcione, como tu género y fecha de nacimiento.</li>
                        <li>Cuando completes tu perfil, puedes compartir con nosotros más información, como detalles sobre tu estilo de vida, gustos y preferencias, y otros datos sobre ti, así como contenido de fotos y/o vídeos). Para añadir cierto contenido, como fotos y vídeos, lo más seguro es que nos permitas acceder a tu cámara o álbum de fotos. Es posible que parte de la información que nos proporciones se considere como "especial" o "sensible" en determinadas jurisdicciones, por ejemplo, tu origen racial o étnico, orientación sexual y creencias religiosas. Al facilitarnos esta información, nos das también tu consentimiento para que la procesemos.</li>
                        <li>Cuando te suscribas a un servicio de pago o realices una compra directamente a través de nosotros (en lugar de hacerlo a través de una plataforma como iOS o Android), proporcionas información (a nosotros o a nuestro proveedor de servicios de pago) como tu número de tarjeta de débito o de crédito u otros datos financieros.</li>
                        <li>Cuando participas en encuestas o grupos de discusión, nos das tu opinión sobre nuestros productos y servicios, respondes a nuestras preguntas y nos aportas recomendaciones.</li>
                        <li>Si decides participar en nuestras promociones, eventos o concursos, recopilaremos la información que usas para registrarte o entrar.</li>
                        <li>Si contactas con nuestro equipo de atención al cliente, recopilaremos la información que nos des durante la interacción. A veces monitorizamos o registramos estas interacciones con fines de formación y para garantizar un servicio de alta calidad.</li>
                        <li>Si nos pides que nos comuniquemos con otras personas o que procesemos información sobre ellas (por ejemplo, si pides que enviemos un correo electrónico en tu nombre a uno de tus amigos), recopilaremos la información que nos des sobre las otras personas para poder completar tu solicitud.</li>
                        <li>Por supuesto, también procesamos tus chats con otros usuarios y el contenido que publicas y compartes con ellos, como parte del uso habitual de los servicios.</li>
                    </ul>
                    <b>Información que recibida de terceros.</b>
                    Tenemos que informarte que existe otra información adicional que recibimos por parte de terceros, y hace referencia a:
                    <ul>
                        <li><b>Otros usuarios</b> Otros usuarios pueden proporcionar información sobre ti cuando utilizan nuestros servicios. Por ejemplo, podemos recopilar datos sobre ti de otros usuarios si estos se comunican con nosotros para informarnos de ti.</li>
                        <li><b>Redes sociales</b> Puedes utilizar tus datos de acceso a las redes sociales (como tu usuario y contraseña de Facebook) para crear tu cuenta de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> o para iniciar sesión. Esto evitará que tengas que recordar más usuarios y contraseñas y te permitirá compartir cierta información de tu cuenta de redes sociales con nosotros.</li>
                        <li><b>Nuestros aliados estratégicos</b> Es posible que recibamos información tuya de nuestros aliados estratégicos. Por ejemplo, cuando se publican anuncios de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> en las páginas web y plataformas de un aliado estratégico</li>
                    </ul> 

                    <b>Información recopilada cuando usas nuestros servicios</b>
                    Cuando usas nuestros servicios, recopilamos información sobre las funciones que has utilizado, cómo las has utilizado y qué dispositivos usas para acceder a nuestros servicios. Continúa leyendo para obtener más información:
                    <ul>
                        <li><b>Información de uso </b>Recopilamos información sobre tu actividad en nuestros servicios. Por ejemplo, cómo los usas (los cuales pueden ser: fecha y hora en que inicias sesión, funciones que utilizas, búsquedas, clics, páginas que se te han mostrado, dirección de las páginas web de referencia o anuncios en los que has hecho clic) y cómo interactúas con otros usuarios (p. ej., usuarios con los que conectas e interactúas, fecha y hora de tus interacciones o número de mensajes que envías y recibes).</li>
                        <li>
                            <b>Información sobre dispositivos </b>Recopilamos información sobre los dispositivos que utilizas para acceder a nuestros servicios. Entre esta información se incluye:
                          <ul>
                              <li>Información de hardware y software, como la dirección IP, el ID y tipo del dispositivo, ajustes y características específicas del dispositivo y sus apps, bloqueos de aplicaciones, ID de anuncios (como el AAID de Google y el IDFA de Apple, ambos números generados al azar que se pueden restablecer mediante los ajustes del dispositivo), tipo, versión e idioma del navegador, sistema operativo, zona horaria, identificadores asociados con cookies u otras tecnologías que puedan identificar tu dispositivo o navegador (p. ej., IMEI/UDID y dirección MAC);</li>
                              <li>Información sobre tu conexión a redes Wi-Fi y redes móviles como, por ejemplo, tu proveedor de servicios y la intensidad de la señal.</li>
                              <li>Información sobre los sensores del dispositivo, como acelerómetros, giroscopios y brújulas.</li>
                          </ul>  
                        </li>
                        <li><b>Otra información con tu consentimiento </b>Si nos das permiso, podemos recopilar tu geolocalización precisa (latitud y longitud) a través de varios medios, dependiendo del servicio y el dispositivo que estés utilizando, incluyendo GPS, Bluetooth o conexiones Wi-Fi. La recopilación de la información sobre tu geolocalización se lleva a cabo en segundo plano en el dispositivo, incluso cuando no estés usando el servicio, siempre y cuando el permiso que nos hayas otorgado permita dicha recopilación. Si no nos das permiso para recopilar tus datos de geolocalización, no los recopilaremos. Asimismo, puedes darnos tu consentimiento para recopilar tus fotos y vídeos (por ejemplo, si quieres publicar una foto, vídeo o streaming en los servicios).</li>
                    </ul>
                    </p>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12">
                    <b>4. Cookies y otras tecnologías de recopilación de datos similares</b>
                    <p>
                    Usamos, y es posible que permitamos que otros usen, cookies y tecnologías similares para reconocerte a ti o a tus dispositivos. Puedes leer nuestra <Link ti="politicas-de-cookies">Política de cookies de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b></Link> para obtener más información sobre su finalidad (por ejemplo, sirven para autenticarte, recordar tus ajustes y preferencias, analizar el tráfico y las tendencias de la página, ejecutar campañas publicitarias y medir su eficacia, y te permiten usar funciones sociales) y cómo puedes controlar correctamente su uso mediante los ajustes del navegador y otras herramientas.
                    Algunos navegadores web (entre los que se incluyen Safari, Internet Explorer, Firefox y Chrome) tienen una opción de "no realizar seguimiento" (Do Not Track, " DNT ") que indica al sitio web que el usuario no desea que se realice un seguimiento de su actividad online. En caso de que un sitio web que responda a señales DNT reciba una, el navegador puede impedir que dicho sitio web recopile determinada información sobre el usuario del navegador. No todos los navegadores ofrecen una opción DNT y las señales DNT todavía no funcionan de forma homogénea. Esta es la razón por la que muchas empresas, entre ellas <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>, no responden actualmente a las señales DNT.
                    </p>
                </div>
            </div> 

            <div className="row">
                <div className="col-lg-12">
                    <b>5. Cómo procesamos tu información?</b>
                    <p>
                    La razón fundamental de usar tu información es para poder brindarte cada día un mejor servicio, cuidar tu información y poder mantenerte informado con temas que sabemos son de tu interés 
                    <b>Administrar tu cuenta y proporcionar nuestros servicios</b>
                    <ul>
                        <li>Crear y gestionar tu cuenta</li>
                        <li>Ofrecerte servicios de atención al cliente y atender tus solicitudes</li>
                        <li>Llevar a cabo tus transacciones</li>
                        <li>Comunicarnos contigo sobre los servicios, incluyendo la gestión de pedidos y la facturación</li>
                    </ul>
                    <b>Ayudarte a conectar con otros usuarios</b>
                    <ul>
                        <li>Nuestro algoritmo analiza tu perfil, frecuencia de uso, gustos y preferencias para seleccionarte una baraja selecta de perfiles de usuarios y tengas los catches esperados y recomendar tu perfil a otros. Te invitamos a consultar <Link to="FAQS" target="_blank">preguntas frecuentes</Link> para que entiendas más sobre cómo funciona la plataforma <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b></li>
                        <li>Emparejar los perfiles de los usuarios según ubicación, preferencias, gustos para presentarlos a otros usuarios.</li>
                    </ul>
                    <b>Garantizar una experiencia homogénea en todos tus dispositivos</b>
                    <ul>
                        <li>Enlazar los diferentes dispositivos que uses para que puedas disfrutar de una experiencia homogénea de los servicios en todos ellos. Para esto, enlazamos los dispositivos y los datos del navegador, por ejemplo, cuándo inicias sesión en tu cuenta desde dispositivos diferentes, si usas una dirección IP completa o parcial, la versión del navegador y datos similares de tus dispositivos para su identificación y vinculación.</li>
                    </ul>
                    <b>Ofrecerte nuevos servicios de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b></b>
                    <ul>
                        <li>Registrarte y mostrar tu perfil en nuevas funciones y apps de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b></li>
                        <li>Administrar tu cuenta en estas nuevas funciones y apps.</li>
                        <li>Aumentar tus posibilidades de enlaces efectivos mediante el uso de tu perfil en nuestras redes sociales.</li>
                    </ul>
                    <b>Mostrarte ofertas y anuncios relevantes</b>
                    <ul>
                        <li>Administrar sorteos, concursos, descuentos y otras ofertas</li>
                        <li>Desarrollar y presentar contenido y anuncios personalizados y adaptados a tus intereses en nuestros servicios y otros sitios web, y hacer un seguimiento de ese contenido</li>
                        <li>Comunicarnos contigo mediante correo electrónico, teléfono, redes sociales o dispositivos móviles para informarte sobre productos o servicios que te podrían interesar</li>
                    </ul>
                    <b>Mejorar nuestros servicios y desarrollar servicios nuevos</b>
                    <ul>
                        <li>Administrar los grupos de discusión y las encuestas</li>
                        <li>Investigar y analizar el comportamiento de los usuarios para mejorar nuestros servicios y contenidos (por ejemplo, podríamos cambiar el aspecto y estilo de una nueva función, o incluso modificarla sustancialmente, en función del comportamiento de los usuarios)</li>
                        <li>Desarrollar nuevas funciones y servicios (por ejemplo, podríamos desarrollar aún más una función basada en los intereses atendiendo las solicitudes de los usuarios).</li>
                    </ul>
                    <b>Prevenir, detectar y combatir el fraude u otras actividades ilegales o no autorizadas</b>
                    <ul>
                        <li>Abordar un mal comportamiento presunto o persistente, dentro o fuera de la plataforma</li>
                        <li>Realizar análisis de la información para conocer mejor este tipo de actividades y diseñar contramedidas más eficaces</li>
                        <li>Guardar información sobre actividades fraudulentas para evitar que vuelvan a suceder en el futuro</li>
                    </ul>
                    <b>Garantizar el cumplimiento de la ley</b>
                    <ul>
                        <li>Cumplir con los requisitos legales</li>
                        <li>Ayudar a las fuerzas del orden</li>
                        <li>Aplicar o hacer uso de nuestros derechos, por ejemplo, de nuestros Términos</li>
                    </ul>
                    Para procesar tu información tal como se ha descrito anteriormente, nos servimos de las siguientes bases legales:
                    <ul>
                        <li><i>Prestar nuestros servicios</i>: En general, la razón por la que procesamos tu información es para cumplir con el contrato que tenemos contigo. Por ejemplo, cuando usas nuestros servicios para desarrollar conexiones relevantes, nosotros usamos tu información para gestionar tu cuenta y tu perfil, hacerlo visible para otros usuarios y recomendarte otros usuarios.</li>
                        <li><i>Intereses legítimos</i>: Podemos usar tu información siempre que tengamos un interés legítimo para ello. Por ejemplo, analizamos el comportamiento de los usuarios en nuestros servicios para mejorar de forma continua nuestras ofertas, te sugerimos ofertas que pensamos que pueden interesarte y procesamos información con fines administrativos, legales y para la detección de fraudes.</li>
                        <li><i>Consentimiento</i>: Es posible que de vez en cuanto te pidamos permiso para usar tu información por razones específicas. Puedes retirar tu consentimiento en cualquier momento poniéndote en contacto con nosotros en la dirección que encontrarás al final de esta Política de privacidad.</li>
                    </ul>
                    </p>
                </div>
            </div>        

            <div className="row">
                <div className="col-lg-12">
                    <b>6. Cómo compartimos la información</b>
                    <p>
                    Dado que nuestro objetivo es ayudarte a desarrollar conexiones relevantes, con quién más compartimos la información de los usuarios es, por supuesto, con otros usuarios. También compartimos determinada información de los usuarios con proveedores de servicios y aliado estratégicos que nos ayudan a gestionar los servicios y en algunos casos, con entidades legales. Continúa leyendo para obtener más detalles sobre cómo compartimos tu información con otros.
                    <ul>
                        <li><b>Con otros usuarios</b><br/> Compartes información con otros usuarios cuando revelas voluntariamente información en los servicios (incluido tu perfil público). Ten cuidado con tu información y asegúrate de que el contenido que compartes sean cosas que no te importe que se vean públicamente. Recuerda que ni tú ni nosotros podemos controlar lo que otros hacen con tu información una vez que la compartes.
                        <br/>
                        En caso de que decidas restringir la audiencia de todo o parte de tu perfil, o de determinado contenido o información sobre ti, la información será visible en función de tus ajustes.
                        </li>
                        <li><b>Con nuestros proveedores de servicios y aliado estratégicos</b><br/>Usamos terceros para que nos ayudan a gestionar y mejorar nuestros servicios. Estos terceros nos ayudan con varias tareas, como el alojamiento y mantenimiento de datos, los análisis, la atención al cliente, la publicidad, los anuncios, el procesamiento de pagos y las operaciones de seguridad.
                        <br/>
                        También podemos compartir información con aliado estratégicos que distribuyan y nos ayuden con la publicidad de nuestros servicios. Por ejemplo, es posible que compartamos información limitada sobre ti (de manera cifrada y en formato no legible para las personas) con nuestros aliados estratégicos publicitarios.
                        <br/>
                        Seguimos un estricto proceso de investigación antes de contratar a un proveedor de servicios o trabajar con un aliado estratégico. Todos nuestros proveedores de servicios y aliados estratégicos deben aceptar estrictas obligaciones de confidencialidad.
                        </li>
                        <li><b>Por transacciones corporativas</b><br/>Podemos transferir tu información si participamos, de forma total o parcial, en una fusión, venta, adquisición, desinversión, reestructuración, reorganización, disolución, quiebra u otro cambio en la propiedad o control.</li>
                        <li><b>Cuando la ley lo requiera</b><br/> Es posible que revelemos tu información si fuese razonablemente necesario: (i) para cumplir con un proceso legal, como una orden judicial, comparecencia u orden de registro, investigación del gobierno/las fuerzas del orden u otros requisitos legales; (ii) para prevenir y detectar actividades delictivas (en función de las leyes pertinentes en cada caso), o (iii) para salvaguardar la seguridad de una persona.</li>
                        <li><b>Para ejecutar derechos legales</b><br/>También podemos compartir información: (i) si la revelación de dicha información mitigase nuestra responsabilidad en un demanda posible o real; (ii) según sea necesario para salvaguardar nuestros derechos legales y los derechos legales de nuestros usuarios, aliado estratégicos comerciales u otras partes interesadas; (iii) para hacer cumplir nuestros acuerdos contigo, y (iv) para investigar o prevenir actividades criminales, presuntos fraudes u otros actos indebidos, o tomar cualquier otra medida en consecuencia.</li>
                        <li><b>Con tu consentimiento o a petición tuya</b><br/>Es posible que pidamos tu consentimiento para compartir tu información con terceros. En tal caso, explicaremos claramente el motivo para compartir la información.
                        <br/>
                        Podemos usar y compartir información no personal (información que, por sí misma, no identifica a la persona, como información sobre el dispositivo, datos demográficos generales, datos de comportamiento generales, geolocalización en formato no identificable), así como información personal cifrada e ilegible para las personas, bajo cualquiera de las circunstancias anteriormente mencionadas. También podemos compartir esta información con otras empresas y terceros (principalmente anunciantes) para desarrollar y ofrecer anuncios personalizados en nuestros servicios y sitios web o en las aplicaciones de terceros, y para analizar y elaborar informes sobre los anuncios que ves. Es posible que combinemos esta información con información adicional no personal o personal, cifrada y en formato ilegible para las personas, que hayamos recopilado de otras fuentes. Puedes obtener más información sobre el uso que hacemos de las cookies y tecnologías similares en nuestra <Link to="/politicas-de-cookies">Política de cookies</Link>
                        </li>
                    </ul>
                    </p>
                </div>
            </div>   

            <div className="row">
                <div className="col-lg-12">
                    <b>7. Transferencias de datos transfronterizas</b>
                    <p>
                    En ocasiones, compartir la información descrita en la Sección 6 implica realizar transferencias de datos transfronterizas, por ejemplo, a los Estados Unidos de América y otras jurisdicciones. Este es el caso cuando se transfiere la información personal de usuarios ubicados en el Espacio Económico Europeo ("EEE") a países fuera del mismo. Usamos cláusulas contractuales tipo aprobadas por la Comisión Europea u otras salvaguardas adecuadas para permitir las transferencias de datos transfronterizas desde el EEE a otros países. Las cláusulas contractuales tipo son compromisos entre las empresas que transfieren los datos personales; les obligan a proteger la privacidad y seguridad de tu información.
                    </p>
                </div>
            </div>   

            <div className="row">
                <div className="col-lg-12">
                    <b>8. Tus derechos</b>
                    <p>
                    Queremos que tengas el control de tu información, por lo que te proporcionamos las siguientes herramientas:
                    <ul>
                        <li><b>Acceso/Actualización de herramientas en el servicio.</b>Herramientas y configuración de la cuenta que te ayudan a acceder, rectificar o eliminar la información que nos has proporcionado y que está asociada a tu cuenta directamente dentro del servicio. Si tienes alguna pregunta sobre dichas herramientas y configuraciones, ponte en contacto con nuestro equipo de atención al cliente para que te ayuden aquí.</li>
                        <li><b>Permisos del dispositivo. </b>Las plataformas móviles tienen sistemas de permiso para tipos específicos de datos y notificaciones de dispositivos, como los servicios de directorio telefónico y de localización, así como las notificaciones push. Puedes cambiar la configuración de tu dispositivo para consentir u oponerte a la recopilación de la información correspondiente o a la visualización de las notificaciones correspondientes. Por supuesto, si haces esto, ciertos servicios pueden perder su funcionalidad completa.</li>
                        <li><b>Eliminación.</b>Puedes eliminar tu cuenta usando la función correspondiente directamente desde el servicio.</li>
                    </ul>
                    Queremos que conozcas tus derechos de privacidad. He aquí algunos puntos clave que debes recordar:
                    <ul>
                        <li><b>Revisar tu información. </b>Las leyes de privacidad aplicables pueden darte derecho a revisar la información personal que tenemos sobre ti (dependiendo de la jurisdicción, esto puede llamarse derecho de acceso, derecho de portabilidad o alguna variante de esos términos). Puedes solicitar una copia de tu información personal mediante una solicitud aquí.</li>
                        <li><b>Actualización de tu información. </b>Si crees que la información que guardamos sobre ti es inexacta o que ya no tenemos derecho a usarla y deseas solicitar que se corrija, se elimine o que no se procese, ponte en contacto con nosotros aquí.</li>
                    </ul> 
                    Para tu protección y la de todos nuestros usuarios, podemos solicitarte que aportes pruebas de identidad antes de atender las solicitudes anteriores.
                    <br/>
                    Ten en cuenta que podemos rechazar ciertas solicitudes por diversas razones, por ejemplo, que la solicitud sea ilegal o viole los secretos comerciales, la propiedad intelectual o la privacidad de otros usuarios. Se deseas obtener información sobre otro usuario, como una copia de cualquier mensaje que te haya enviado a través de nuestros servicios, el otro usuario tendrá que ponerse en contacto con nuestro responsable de privacidad para dar su consentimiento escrito antes de revelar la información.
                    <br/>
                    Además, habrá ciertas solicitudes contra el procesamiento que no podremos atender, sobre todo si dichas solicitudes impiden continuar prestándote nuestros servicios. Por ejemplo, no podemos prestar nuestros servicios si no tenemos tu fecha de nacimiento.
                    <ul>
                        <li><b>Desinstalación. </b>Puedes detener toda la recopilación de información de una aplicación desinstalándola mediante el proceso de desinstalación estándar de tu dispositivo. Si desinstalas la aplicación de tu dispositivo móvil, el identificador único asociado a tu dispositivo seguirá almacenado. Si reinstalas la aplicación en el mismo dispositivo móvil, podremos volver a asociar este identificador a tus transacciones y actividades anteriores.</li>
                        <li><b>Rendición de cuentas. </b>En ciertos países, incluidos los de la Unión Europea, tienes derecho a presentar una reclamación ante la autoridad de protección de datos correspondiente si te preocupa cómo procesamos tu información personal. La autoridad de protección de datos ante la que puedes presentar una reclamación puede ser la de tu residencia habitual, la del lugar donde trabajas o la del lugar donde nosotros estamos establecidos.</li>
                    </ul>
                    </p>
                </div>
            </div> 

            <div className="row">
                <div className="col-lg-12">
                    <b>9. Residentes en California</b>
                    <p>
                    Si resides en California, puedes solicitar una notificación en la que se te informe de las categorías de tu información personal que hemos compartido con terceros para sus fines publicitarios directos durante el año anterior. Para solicitar esta notificación, envía tu solicitud aquí. La respuesta no tardará más de 30 días. Para tu protección y la de todos nuestros usuarios, podemos solicitarte que aportes pruebas de identidad antes de atender tu solicitud.
                    </p>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12">
                    <b>10. Cómo protegemos tu información personal</b>
                    <p>
                    Trabajamos duramente para protegerte contra accesos no autorizados a tu información personal y contra la alteración, divulgación o destrucción de la misma. Como todas las empresas tecnológicas, aunque tomamos medidas para salvaguardar tu información, no podemos prometer, y no debes esperar, que tu información personal esté segura en todo momento.
                    <br/>
                    Monitorizamos nuestros sistemas con regularidad en busca de posibles puntos débiles y ataques y también revisamos regularmente nuestras prácticas de recopilación, almacenamiento y procesamiento de información para actualizar nuestras medidas de seguridad tanto físicas como técnicas y organizativas.
                    <br/>
                    En caso de que sospechemos o detectemos una infracción de seguridad, podemos suspender total o parcialmente, sin previo aviso, el uso que haces de nuestros servicios. Se crees que tu cuenta o información ya no está segura, avísanos inmediatamente aquí
                    <br/>
                    Para garantizar que tanto nuestros sistemas como tu información estén protegidos contra accesos no autorizados, robos y pérdidas, hemos puesto en marcha un programa de recompensas por notificación de errores. Haz clic aquí para obtener más información sobre nuestro programa de recompensas por notificación de errores.
                    </p>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12">
                    <b>11. Cuánto tiempo conservamos tu información</b>
                    <p>
                    Únicamente guardamos tu información personal durante el tiempo que la necesitemos para nuestros fines comerciales legítimos (como se describe en la Sección 5) y siempre que lo permitan las leyes vigentes. A fin de salvaguardar la seguridad de nuestros usuarios tanto dentro como fuera de nuestros servicios, hemos implementado una ventana de conservación de seguridad de tres meses desde el momento en que se elimine una cuenta. La información de la cuenta se conservará durante este periodo, aunque por supuesto la cuenta ya no aparecerá en los servicios.
                    <br/>
                    En la práctica, eliminamos y hacemos anónima tu información una vez que eliminas la cuenta (después del periodo de conservación de seguridad) o tras dos años de inactividad continuada, a menos que:
                    <ol style={{listStyleType: 'decimal-leading-zero'}}>
                        <li>Debamos conservarla para cumplir con las leyes vigentes (por ejemplo, determinados "datos de tráfico" se conservan durante un año para cumplir con las obligaciones legales de conservación de datos);</li> 
                        <li>debamos conservarla para demostrar nuestro cumplimiento con las leyes vigentes (por ejemplo, los registros de consentimientos de los Términos de uso, Política de privacidad y otros consentimientos similares se conservan durante cinco años);</li> 
                        <li>exista un problema, conflicto o queja excepcional que requiera que conservemos la información relevante hasta que dicho asunto se solucione; o</li> 
                        <li>se deba conservar la información para nuestros intereses comerciales legítimos, por ejemplo, para prevenir el fraude y mejorar la seguridad y protección de los usuarios. Así, por ejemplo, es posible que tengamos que conservar la información para evitar que un usuario excluido por mal comportamiento o incidentes de seguridad abra otra cuenta.</li> 
                    </ol>
                    Ten en cuenta que, aunque nuestros sistemas están pensados para eliminar la información según las pautas anteriores, no podemos garantizar que se eliminarán todos los datos antes de un periodo de tiempo específico debido a que se pueden dar dificultades técnicas.
                    </p>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12">
                    <b>12. Privacidad de menores</b>
                    <p>
                    Nuestros servicios se restringen a usuarios que tengan más de 18 años. No permitimos que menores de 18 años usen nuestra plataforma y no recopilamos, al menos conscientemente, información personal de personas menores de 18 años. Si sospechas que un usuario tiene menos de 18 años, comunícalo a través de nuestro mecanismo de denuncias, disponible en los servicios.
                    </p>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12">
                    <b>13. Cambios en la política de privacidad</b>
                    <p>
                    Dado que siempre estamos buscando nuevas e innovadoras formas de ayudarte a desarrollar conexiones relevantes, es posible que esta política cambie con el tiempo. Te avisaremos antes de que entre en vigor cualquier cambio significativo para que tengas tiempo de revisarlo.
                    </p>
                </div>
            </div>  

            <div className="row">
                <div className="col-lg-12">
                    <b>14. Cómo ponerse en contacto con nosotros</b>
                    <p>
                    Si tienes dudas sobre esta Política de privacidad, estos son nuestros datos de contacto:
                    <b>Si vives en norte américa:</b>
                    Te puedes poner en contacto con BITHE. LLC , mediante correspondencia física en la siguiente dirección 6960NW 186 ST APTO 322 Hialeah florida 33002, o mediante el correo electrónico: hi@e-hunterapp.com
                    <b>Si vives en centro o sur américa:</b>
                    Te puedes poner en contacto con BITHE.SAS , mediante el correo electrónico hi@-hunterapp.com, o correspondencia física a la siguiente dirección: carrera 43 # 72 – 192 0ficina 403 Edificio Aloha, Barranquilla -Colombia
                    </p>
                </div>
            </div>   
            
    </div>
    )
}

export default Uso