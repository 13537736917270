import React from 'react'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';


const Nosotros = () => {
    return(
        <div className="container" style={{marginTop:'8em', fontFamily:'poppins'}}>
            <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                <div className="row">
                    <div className="col-lg-12 text-center mb-3">
                        <b style={{fontSize:'22pt', color:'#4f4f4f'}}>Sobre nosotros</b>
                    </div>
                </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                <div className="row">
                    <div className="col-lg-12">
                    <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>
                        <p>
                        Conoce ya al amor de tu vida, a tu media naranja, conoce nuevos amigos, entabla nuevas relaciones profesionales o simplemente ve por una aventura express.
                        <br/>
                        <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> se proyecta como la app de citas más popular de estados unidos y latino américa y una de las más usadas a nivel mundial, superando por mucho a otras aplicaciones de citas por su versatilidad, facilidad de usar, diseños mejorados y características únicas que hacen de <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b> una verdadera novedad donde Luchamos para que no sientas esas frustraciones que te ofrecen otras apps y que sientas una experiencia renovada, dinámica y con mejores y selectos perfiles de usuarios; <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>, será tu compañero en cada viaje en todos los rincones del planeta, podrás conocer personas en cada lugar que visites. ya sea para tener una relación seria, solo amistad, relación profesional o de negocios, una aventura de una noche, un amor de vacaciones o simplemente para ampliar tu círculo social, es tan fácil de usar que solo debes presionar el corazón o deslizar a la derecha cuanto te sientas interesado en alguna persona o presionar el corazón roto o deslizar a la izquierda cuando no tengas interés en alguien, el algoritmo detectará el interés mutuo y hará un catch (atraparas a esa persona) Con <b>E-HUNTER <span style={{fontSize:'8pt'}}>APP</span></b>, podrás tener tu perfil básico que incluye características únicas en su segmento, podrás enviar un número limitado de imágenes, de audios, video llamadas, salir de cacería express y explotar tu perfil para hacerlo más visible en ciertos momentos y lugares; también contaras con el perfil Diamont el cual es el más top del mercado y que te ofrece un sin número de características ilimitadas que aumentaran tus posibilidades de hacer muchos catch y encontrar lo que estas buscando.
                        </p>
                    </div>
                </div> 
            </ScrollAnimation> 
    </div>
    )
}

export default Nosotros